// Login messages
export const UNABLE_TO_CONNECT_SERVICE = 'Unable to connect. Try again later.';
export const UNKNOWN_LOGIN_FAILURE = 'Unable to log in. Try again later.';
export const CREATE_PASSWORD = 'You must create a new password.';
export const PASSWORD_EXPIRED = 'Your password has expired. Please create a new password.';
export const UNKNOWN_DEVICE = 'This device is unknown. An email with a verification code has been sent to your SSO email address.';

// Growl messages
export const UNABLE_TO_RETRIEVE_USERS = 'Unable to retrieve users.';
export const UNABLE_TO_RETRIEVE_USER = 'Unable to retrieve user.';
export const USER_ADD_SUCCESSFUL = 'User Added.';
export const USER_ADD_FAILED = 'Unable to Add User.';
export const USER_SAVE_SUCCESSFUL = 'User Updated.';
export const USER_SAVE_FAILED = 'Unable to Update User.';
export const PASSWORD_RESET_SUCCESSFUL = 'Password Reset.';
export const PASSWORD_RESET_FAILED = 'Unable to Reset Password.';
export const REPORT_EXPORT_SUCCESSFUL = 'Report Retrieved.';
export const REPORT_EXPORT_FAILED = 'Unable to Retrieve Report.';
export const UNABLE_TO_RETRIEVE_NOTIFICATIONS = 'Unable to retrieve notifications.';

// Dialog message
export const SIGN_IN_WITH_NEW_EMAIL = 'Sign in with your new email address.';

// Misc validation messages
export const REQUIRED = 'Required';
export const EMAIL_INVALID = 'Invalid Email Address.';
export const PASSWORDS_MUST_MATCH = 'Passwords must match.';
export const BAD_PHONE_NUMBER_FORMAT = 'Format must be (234) 244-1244 or (234) 244-1244 x123.';
export const INCOMPLETE_ADDRESS_NOT_ALLOWED = 'Address fields must be complete or all empty.';
export const NO_ROLES_ASSIGNED = 'No roles assigned to user.';
export const END_DATE_BEFORE_START_DATE = 'End date before start date.';
export const AUDIT_REPORT_DATE_RANGE_LIMIT = 'Range cannot exceed 365 days.';
export const MUST_BE_VALID_DATE = 'Must be a valid date.';

// Other messages
export const NO_USERS_FOUND = 'No users found.';